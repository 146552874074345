import React from 'react';

const VisionPage = () => {
  return (
    <div className="vision-page">
      <h1>חזון 2025 לשומרי ציון</h1>
      <div className="vision-content">
        <h2>מבוא</h2>
        <p>
          עד שנת 2025, החזון שלנו הוא להקים ארגון שורשים דינמי ומשפיע המבוסס על עקרונות היהדות. המשימה שלנו היא לחזק את ערכי המשפחה, להגדיל את שיעורי הילודה, לאסוף יותר תומכים, במיוחד מקרב הנוער, ולדגול בחברה המושתתת על מסורת יהודית. ארגון זה יעמוד בנחישות נגד אידיאולוגיות אסלאמיות והשפעות פרוגרסיביות וחרדיות, במטרה להחזיר את השליטה על המדיה, מערכת המשפט, צה"ל ומערכת החינוך.
        </p>

        <h2>ערכים ומשימה מרכזיים</h2>
        <p>
          הארגון שלנו מבוסס על הערכים הבאים:
        </p>
        <ul>
          <li><strong>יהדות:</strong> מחויבות להלכה היהודית, מסורת ולימודי התורה.</li>
          <li><strong>ערכי משפחה:</strong> קידום קדושת המשפחה, עידוד נישואין וטיפוח סביבה תומכת לגידול ילדים.</li>
          <li><strong>מעורבות נוער:</strong> השראה ומעורבות של הדור הצעיר בפעילויות דתיות, תרבותיות וחברתיות.</li>
          <li><strong>גידול אוכלוסייה:</strong> עידוד שיעורי ילודה גבוהים יותר כדי להבטיח את המשכיות העם היהודי.</li>
        </ul>

        <h2>מטרות אסטרטגיות</h2>
        <ol>
          <li>
            <h3>התיישבות ביהודה ושומרון, בדרום ובצפון</h3>
            <ul>
              <li><strong>התרחבות:</strong> תמיכה ועידוד פעיל של התיישבות משפחות יהודיות ביהודה ושומרון, בנגב הדרומי ובגליל הצפוני.</li>
              <li><strong>פיתוח תשתיות:</strong> השקעה בפיתוח תשתיות כגון כבישים, בתי ספר, בתי חולים ומרכזים קהילתיים באזורים אלו.</li>
              <li><strong>אמצעי בטחון:</strong> שיתוף פעולה עם רשויות מקומיות וארציות להבטחת אמצעי ביטחון חזקים למתיישבים.</li>
            </ul>
          </li>
          <li>
            <h3>מאבק בהשפעות פרוגרסיביות וחרדיות</h3>
            <ul>
              <li><strong>התנגדות תרבותית:</strong> פיתוח תוכניות וקמפיינים חינוכיים להתנגדות לאידיאולוגיות פרוגרסיביות המערערות ערכים יהודיים מסורתיים.</li>
              <li><strong>מעורבות עם הקהילה החרדית:</strong> טיפוח דיאלוג והבנה עם הקהילה החרדית תוך עמידה על גישה מאוזנת לשמירה דתית ושירות לאומי.</li>
            </ul>
          </li>
          <li>
            <h3>מאבק בשליטה השמאלנית על המדיה, מערכת המשפט וצה"ל</h3>
            <ul>
              <li><strong>מדיה:</strong> הקמת ותמיכה בפלטפורמות מדיה ימניות לספק חדשות מאוזנות ולהתמודד עם ההטיה השמאלנית.</li>
              <li><strong>רפורמה במערכת המשפט:</strong> קידום רפורמות משפטיות להבטחת מערכת משפט הוגנת ובלתי משוחדת המכבדת ערכים יהודיים ואינטרסים לאומיים.</li>
              <li><strong>השפעה על צה"ל:</strong> עידוד הכללת פרספקטיבות דתיות ולאומיות בתוך צה"ל, קידום אתוס צבאי חזק וציוני.</li>
            </ul>
          </li>
          <li>
            <h3>רפורמה חינוכית ופיתוח אינטלקטואלי</h3>
            <ul>
              <li><strong>שינוי תכנית לימודים:</strong> עבודה לשינוי תכנית הלימודים הלאומית כך שתדגיש את ההיסטוריה היהודית, מסורות וערכים.</li>
              <li><strong>יצירת מעמד אינטלקטואלי ימני:</strong> השקעה בהשכלה גבוהה ומכוני מחקר לפיתוח דור חדש של אינטלקטואלים ומנהיגים ימניים.</li>
              <li><strong>תוכניות לנוער:</strong> פיתוח תוכניות נוער ויוזמות חינוכיות להטמעת גאווה בזהות יהודית ומחויבות לשירות לאומי.</li>
            </ul>
          </li>
        </ol>

        <h2>מעורבות והגעה לציבור</h2>
        <ol>
          <li>
            <h3>בניית קהילה</h3>
            <ul>
              <li><strong>סניפים מקומיים:</strong> הקמת סניפים מקומיים ברחבי ישראל לטיפוח מעורבות קהילתית ופעילות שורשים.</li>
              <li><strong>תמיכה במשפחות:</strong> מתן משאבים ותמיכה למשפחות, כולל סיוע כספי, טיפול בילדים וסדנאות להורות.</li>
            </ul>
          </li>
          <li>
            <h3>מעורבות נוער</h3>
            <ul>
              <li><strong>תוכניות מנהיגות:</strong> יצירת תוכניות לפיתוח מנהיגות עבור צעירים להכנתם לתפקידים בארגון ובחברה הרחבה.</li>
              <li><strong>פעילויות חברתיות:</strong> ארגון פעילויות חברתיות, תרבותיות ודתיות כדי למשוך ולשמר חברים צעירים.</li>
            </ul>
          </li>
          <li>
            <h3>קידום ציבורי</h3>
            <ul>
              <li><strong>קמפיינים:</strong> השקת קמפיינים להעלאת מודעות לקידום ערכינו ומטרותינו.</li>
              <li><strong>מעורבות פוליטית:</strong> השתתפות פעילה בתהליך הפוליטי, תמיכה במועמדים ומדיניות המתאימים למשימתנו.</li>
            </ul>
          </li>
        </ol>

        <h2>סיכום</h2>
        <p>
          עד שנת 2025, ארגוננו יהיה כוח מוביל בחברה הישראלית, מקדם חזון של מדינה יהודית חזקה ותוססת המושתתת על מסורת, ערכי משפחה וגאווה לאומית. באמצעות התיישבות אסטרטגית, רפורמה חינוכית ומעורבות קהילתית חזקה, נבטיח את המשכיות ושגשוג העם היהודי בארץ ישראל. מאמצינו ייצרו קהילה מלוכדת וחזקה העומדת איתנה מול איומים חיצוניים ופנימיים, ומטפחת עתיד שבו ערכים ומסורות יהודיים משגשגים.
        </p>
      </div>
    </div>
  );
};

export default VisionPage;